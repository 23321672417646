<template>
    <b-card title="Tax Report">
        <b-form>
            <b-row>
                <b-col md="3">
                    <b-form-group label="Start Date">
                        <flat-pickr class="form-control" name="date" placeholder="Please Enter Start Date" />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="End Date">
                        <flat-pickr class="form-control" name="date" placeholder="Please Enter End Date" />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Report Type">
                        <b-form-input placeholder="Please Enter Report Type"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-button variant="primary" class="mt-2 mr-1">
                        <feather-icon icon="FileTextIcon" class="mr-50" />Export
                    </b-button>
                    <b-button variant="primary" class="mt-2 mr-1">
                        <feather-icon icon="PrinterIcon" class="mr-50" />Print
                    </b-button>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col md="12">
                    <!-- table -->
                    <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm }" :select-options="{
                      enabled: false,
                      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                      selectionInfoClass: 'custom-class',
                      selectionText: 'rows selected',
                      clearSelectionText: 'clear',
                      disableSelectInfo: true, // disable the select info panel on top
                      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }" :pagination-options="{
                      enabled: true,
                      perPage:pageLength
                    }">
                        <template slot="table-row" slot-scope="props">

                            <!-- Column: Name -->
                            <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                                <b-avatar :src="props.row.avatar" class="mx-1" />
                                <span class="text-nowrap">{{ props.row.fullName }}</span>
                            </span>

                            <!-- Column: Status -->
                            <span v-else-if="props.column.field === 'status'">
                                <b-badge :variant="statusVariant(props.row.status)">
                                    {{ props.row.status }}
                                </b-badge>
                            </span>



                            <!-- Column: Common -->
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                        <!-- pagination -->
                        <template slot="pagination-bottom" slot-scope="props">
                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        Showing 1 to
                                    </span>
                                    <b-form-select v-model="pageLength" :options="['10', '20', '50','100']" class="mx-1"
                                        @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                                </div>
                                <div>
                                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                        first-number last-number align="right" prev-class="prev-item"
                                        next-class="next-item" class="mt-1 mb-0"
                                        @input="(value)=>props.pageChanged({currentPage:value})">
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>
                        </template>
                    </vue-good-table>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BCard, BForm, BRow, BCol, BFormGroup, BButton, BButtonGroup, BFormInput,BFormSelect,BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
    components: {
        VueGoodTable, vSelect, BCard, BForm, BRow, BCol, BFormGroup,
        BButton, BButtonGroup, BFormInput, flatPickr,BFormSelect,BPagination,
    },
    data() {
        return {
            pageLength: 5,
            dir: false,
            columns: [
                {
                    label: 'OrderNo / SRN',
                    field: 'orderNo',
                },
                {
                    label: 'Customer Name',
                    field: 'customerName',
                },
                {
                    label: 'Address',
                    field: 'address',
                },
                {
                    label: 'Mobile',
                    field: 'mobile',
                },
                {
                    label: 'Order Date',
                    field: 'orderDate',
                },
                {
                    label: 'Delivery Date',
                    field: 'deliveryDate',
                },
                {
                    label: 'Gross Amount',
                    field: 'grossAmount',
                },
                {
                    label: 'Taxable Amount',
                    field: 'taxableAmount',
                },
                {
                    label: 'Discount',
                    field: 'discount',
                },
                {
                    label: 'IGST',
                    field: 'igst',
                },
                {
                    label: 'CGST',
                    field: 'cgst',
                },
                {
                    label: 'SGST',
                    field: 'sgst',
                },
                {
                    label: 'CICess',
                    field: 'cicess',
                },
                {
                    label: 'KKCess',
                    field: 'kkcess',
                },
                {
                    label: 'Net Amount',
                    field: 'netAmount',
                },
                {
                    label: 'Status',
                    field: 'status',
                },
                {
                    label: 'GSTIN',
                    field: 'gstin',
                },
                {
                    label: 'State',
                    field: 'state',
                },
                {
                    label: 'Type',
                    field: 'type',
                },

            ],
            rows: [],
            searchTerm: '',
        }
    }

}
</script>

<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
